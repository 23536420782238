import BuddyTable from "../../components/BuddyTable/BuddyTable";

const BuddyListPage = () => {
  return (
    <div>
      <BuddyTable />
    </div>
  );
};

export default BuddyListPage;
