import React from "react";
import BoardMembers from "../components/BoardMembers/BoardMembers";
import VolunteerList from "../components/Volunteer/VolunteerList";

const MembersPage = () => {
  return (
    <div>
      <BoardMembers />
      <VolunteerList />
    </div>
  );
};

export default MembersPage;
