import Registration from "../../components/Registration/Registration";

const RegistrationPage = () => {
  return (
    <div>
      <Registration />
    </div>
  );
};

export default RegistrationPage;
